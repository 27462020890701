var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardTable',{staticClass:"categories-table",attrs:{"align-right-after":2,"columns":[
    _vm.$t('general.name'),
    _vm.$t('general.slug'),
    _vm.$t('general.options') ],"show-search":"","state-key":"categories"}},_vm._l((_vm.categories),function(ref){
  var id = ref.id;
  var name = ref.name;
  var slug = ref.slug;
  var breadcrumbs = ref.breadcrumbs;
return _c('CategoryTableRow',{key:id,attrs:{"id":id,"slug":slug,"name":name,"breadcrumbs":breadcrumbs},on:{"edit-category":function (idToEdit) { return _vm.$emit('edit-category', idToEdit); },"delete-category":function (idToDelete) { return _vm.$emit('delete-category', idToDelete); }}})}),1)}
var staticRenderFns = []

export { render, staticRenderFns }