<template>
  <TableRow
    :id="id"
    route-name="categories.edit"
  >
    <td>
      <Breadcrumbs
        :active-breadcrumb="{ id, name, permalink: slug }"
        :breadcrumbs="breadcrumbs"
      />
    </td>
    <td>
      <ChecDataPill>
        {{ slug }}
      </ChecDataPill>
    </td>
    <td>
      <ChecOptionsMenu class="inline-block">
        <!-- Re-enable when the products list supports this filter -->
        <!-- See: https://github.com/chec/dashboard/issues/742 -->
        <!--            <ChecOption -->
        <!--              @option-selected=" -->
        <!--                () => $router.push({ -->
        <!--                  name: 'products.home', -->
        <!--                  query: { 'category-slug': slug.toLowerCase() } -->
        <!--                })" -->
        <!--            > -->
        <!--              {{ $t('product.viewProducts' ) }} -->
        <!--            </ChecOption> -->
        <ChecOption @option-selected="handleEdit">
          {{ $t('general.edit') }}
        </ChecOption>
        <ChecOption destructive @option-selected="handleDelete">
          {{ $t('general.delete') }}
        </ChecOption>
      </ChecOptionsMenu>
    </td>
  </TableRow>
</template>

<script>
import {
  ChecDataPill,
  ChecOption,
  ChecOptionsMenu,
} from '@chec/ui-library';
import TableRow from '@/components/DashboardTable/TableRow.vue';
import Breadcrumbs from '../Breadcrumbs.vue';

export default {
  name: 'CategoryTableRow',
  components: {
    TableRow,
    Breadcrumbs,
    ChecDataPill,
    ChecOption,
    ChecOptionsMenu,
  },
  props: {
    breadcrumbs: Array,
    id: String,
    name: String,
    slug: String,
  },
  methods: {
    handleEdit() {
      this.$emit('edit-category', this.id);
    },
    handleDelete() {
      this.$emit('delete-category', this.id);
    },
  },
};
</script>

<style lang="scss">

</style>
