<template>
  <div class="categories-home">
    <DashboardHeader :title="$t('category.categories')">
      <ChecButton
        color="primary"
        variant="round"
        tag-type="route"
        :to="{ name: 'categories.add' }"
      >
        {{ $t('general.add') }}
        <template #icon>
          <ChecIcon icon="plus" />
        </template>
      </ChecButton>
    </DashboardHeader>
    <IntroSplash
      v-if="showIntroSplash"
      :title="$t('category.introSplash.title')"
      button
      @click="() => $router.push({ name: 'categories.add' })"
    >
      <i18n path="category.introSplash.description" tag="span">
        <template #more>
          <a href="http://support.commercejs.com/en/articles/5544252-add-categories-for-your-products" target="_blank">
            {{ $t('category.introSplash.more') }}
          </a>
        </template>
      </i18n>
    </IntroSplash>
    <CategoriesTable
      v-else
      :categories="categories"
      @edit-category="handleEditCategory"
      @delete-category="handleDeleteCategory"
    />
    <DashboardPagination state-key="categories" />
  </div>
</template>

<script>
import {
  ChecButton,
  ChecIcon,
} from '@chec/ui-library';
import DashboardHeader from '@/components/DashboardHeader.vue';
import DashboardPagination from '@/components/Pagination.vue';
import crud from '@/mixins/crud';
import addNotification from '@/mixins/addNotification';
import confirm from '@/mixins/confirm';
import IntroSplash from '@/components/IntroSplash.vue';
import CategoriesTable from '../components/CategoriesTable.vue';

export default {
  name: 'CategoriesHome',
  components: {
    ChecButton,
    ChecIcon,
    DashboardHeader,
    DashboardPagination,
    CategoriesTable,
    IntroSplash,
  },
  mixins: [crud('categories', true), addNotification, confirm],
  computed: {
    /**
     * Show intro card section when there are no categories and when
     * page is not loading
     *
     * @returns {boolean}
     */
    showIntroSplash() {
      return this.categories.length === 0 && !this.isLoading;
    },
  },
  methods: {
    handleEditCategory(id) {
      this.$router.push({ name: 'categories.edit', params: { id } });
    },
    async handleDeleteCategory(id) {
      if (await this.confirm(
        this.$t('general.areYouSure'),
        this.$t('category.confirmDelete'),
      )) {
        this.delete(id)
          .then(() => {
            this.addNotification(this.$t('category.deleted'));
          })
          .catch((error) => {
            this.addNotification(error, 'error');
          });
      }
    },
  },
};
</script>
