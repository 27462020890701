import { makeApiRequest } from '@/lib/api';

export default {
  data() {
    return {
      uuidsToIds: {},
    };
  },
  methods: {
    /**
     * Plugs into the file upload component from the UI library to create a dynamic upload URL for
     * any given file
     *
     * @param {File} file
     * @returns {Promise}
     */
    resolveEndpoint(file) {
      const name = encodeURIComponent(file.name);
      return makeApiRequest(
        'POST',
        `/v1/assets?filename=${name}&content_type=${file.type}&presigned=1`,
      ).then(({ data: { id, upload_url: data } }) => {
        // Note that this param-reassign is the intended usage of the endpoint resolver
        // eslint-disable-next-line no-param-reassign
        file.formData = data.form_data;
        this.uuidsToIds[file.upload.uuid] = id;
        return data.url;
      }).catch((data) => {
        // Just return the first error from the response (if it's available)
        if (data.error && data.error.errors) {
          throw new Error(Object.values(data.error.errors)[0][0]);
        }

        throw new Error('The given file is not valid');
      });
    },
    /**
     * Hooks into `acceptSuccessfulUpload` prop on the UI library components. Called before a file
     * is marked as complete in the UI, and should return a promise that resolves into the file that
     * will be emitted as the new uploaded file
     *
     * @param {File} file
     * @returns {Promise<Object>}
     */
    completeUpload(file) {
      const id = this.uuidsToIds[file.upload.uuid];
      return makeApiRequest('PUT', `/v1/assets/${id}`, {
        pending: false,
      }).then(({ data: asset }) => asset);
    },
    /**
     * Hooks into the UI library component to convert an asset given as the v-model on the file
     * upload components, and turn it into a `File` that dropzone understands
     *
     * @param {Object} asset
     * @returns {Object}
     */
    normaliseAsset(asset) {
      return {
        id: asset.id,
        name: asset.filename,
        thumb: asset.is_image ? asset.url : null,
        size: asset.file_size,
      };
    },
  },
};
