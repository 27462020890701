<template>
  <ChecSlideoutPanel
    :title="$t('category.addSubCategory')"
    close-on-overlay-click
    class="sub-category-add"
    @close="handleClose"
  >
    <template>
      <div class="sub-category-add__content">
        <ChecHeader
          :title="$t('general.details')"
          class="sub-category-add__subheader"
        />
        <div class="input-wrapper__row space-x-2">
          <div class="input-wrapper">
            <TextField
              v-model="name"
              name="name"
              :value="name"
              :label="$t('general.name')"
              :variant="validationErrors
                && validationErrors['name'] ? 'error' : ''"
            />
            <span
              v-if="validationErrors
                && validationErrors['name']"
              class="input-wrapper__error"
            >
              {{ validationErrors['name'] }}
            </span>
          </div>
          <div class="input-wrapper">
            <TextField
              v-model="permalink"
              name="permalink"
              :value="permalink"
              :label="$t('category.permalink')"
              :variant="validationErrors
                && validationErrors['permalink'] ? 'error' : ''"
            />
            <span
              v-if="validationErrors
                && validationErrors['permalink']"
              class="input-wrapper__error"
            >
              {{ validationErrors['permalink'] }}
            </span>
          </div>
        </div>
        <div class="input-wrapper">
          <TextField
            v-model="subcategory.description"
            name="description"
            multiline
            :label="$t('general.description')"
          />
        </div>
        <ChecHeader
          :title="$t('general.assets')"
          class="sub-category-add__subheader mt-8"
        />
        <ChecImageManager
          v-model="subcategory.assets"
          class="sub-category-add__dropzone"
          :normalise-file="normaliseAsset"
          :endpoint="resolveEndpoint"
          :accept-successful-upload="completeUpload"
          accepted-files="image/jpeg,image/png,image/gif,image/jpg,image/webp"
          :footnote="footnote"
        />
      </div>
    </template>
    <template #toolbar>
      <div class="sub-category-add__toolbar space-x-4">
        <ChecButton text-only color="primary" @click="handleClose">
          {{ $t('general.cancel') }}
        </ChecButton>
        <ChecButton
          button-type="submit"
          :disabled="saving"
          color="primary"
          @click="handleSaveSubCategory"
        >
          {{ saveLabel }}
        </ChecButton>
      </div>
    </template>
  </ChecSlideoutPanel>
</template>

<script>
import {
  ChecButton,
  ChecHeader,
  ChecSlideoutPanel,
  ChecImageManager,
  TextField,
} from '@chec/ui-library';
import upload from '@/mixins/upload';
import addNotification from '@/mixins/addNotification';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import crud from '@/mixins/crud';
import formatSlug from '@/mixins/formatSlug';
import categorySchema from '../schemas/category';

export default {
  name: 'AddSubCategory',
  components: {
    ChecButton,
    ChecHeader,
    ChecSlideoutPanel,
    ChecImageManager,
    TextField,
  },
  mixins: [
    upload,
    formatSlug,
    crud('categories'),
    addNotification,
  ],
  data() {
    return {
      subcategory: {
        name: '',
        parent_id: null,
        description: '',
        permalink: '',
        assets: [],
      },
      saving: false,
      hasPermalinkDeviated: false,
      validationErrors: {},
    };
  },
  computed: {
    /**
     * The label shown for the "save" button
     *
     * @returns {string}
     */
    saveLabel() {
      return this.saving ? this.$t('general.saving') : this.$t('general.saveAndClose');
    },
    footnote() {
      return this.$tc('category.images.types', 3, {
        types: 'PNG, JPG',
        lastType: 'GIF',
      });
    },
    permalink: {
      get() {
        return this.subcategory.permalink;
      },
      set(newValue) {
        this.hasPermalinkDeviated = true;
        this.subcategory.permalink = this.formatSlug(newValue);
      },
    },
    name: {
      get() {
        return this.subcategory.name;
      },
      set(newValue) {
        this.subcategory.name = newValue;
      },
    },
  },
  watch: {
    name(val) {
      if (!this.hasPermalinkDeviated || this.permalink === '') {
        this.subcategory.permalink = this.formatSlug(val);
        this.hasPermalinkDeviated = false;
      }
    },
  },
  mounted() {
    this.subcategory.parent_id = this.$route.params.id;
  },
  methods: {
    handleClose() {
      const categoryId = this.subcategory.parent_id;
      this.$router.push({
        name: 'categories.edit',
        params: { categoryId },
      });
    },
    async handleSaveSubCategory() {
      this.validationErrors = {};
      this.saving = true;
      validateSchemaRequest(categorySchema, this.subcategory, {
        abortEarly: false,
        context: { edit: true },
      })
        .then(
          (validatedData) => this.create(validatedData, true),
        )
        .then(() => {
          this.addNotification(
            this.$t('category.addedSubCategory'),
          );
          this.load(this.subcategory.parent_id, true);
          this.handleClose();
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
          } else {
            this.addNotification(this.$t('category.updateSubCategoryError'), 'error');
          }
        })
        .finally(() => {
          this.saving = false;
        });
    },
  },
};
</script>

<style lang="scss">
.sub-category-add {
  &__alert {
    @apply mb-4;
  }

  &__toolbar {
    @apply flex justify-end w-full;
  }

  &__subheader {
    @apply mb-8;
  }
}
</style>
