<template>
  <form
    class="categories-add-edit"
    @submit.prevent
  >
    <ChecHeader
      :title="pageTitle"
    />
    <ChecLoading v-if="isLoading" without-background />
    <template v-else>
      <Breadcrumbs
        v-if="category && category.breadcrumbs && category.breadcrumbs.length"
        :active-breadcrumb="{ name: category.name }"
        :breadcrumbs="category.breadcrumbs"
        separated-links
        :collapse-limit="5"
        class="categories-add-edit__breadcrumbs"
      />
      <div class="categories-add-edit__content space-y-4 lg:space-y-0">
        <div class="categories-add-edit__main space-y-4">
          <DetailsCard
            v-model="category"
            :errors="validationErrors"
          />
          <Assets
            v-model="category.assets"
          />
        </div>
        <div class="categories-add-edit__side-bar space-y-4">
          <ActionsCard
            :allow-delete="!isAdding"
            :saving="isSaving"
            class="integration-edit__actions-card"
            @delete-category="handleDeleteCategory"
            @save-category="handleSaveCategory"
          />
          <Parents
            v-model="category.parent_id"
            :category-id="categoryId"
          />
          <SubCategoriesCard
            v-if="!isAdding"
            :category-children="category.children"
            @load-sub-category="refreshCategory"
          />
        </div>
        <ActionsCard
          :allow-delete="!isAdding"
          :saving="isSaving"
          class="categories-add-edit__actions-card--mobile"
          @delete-category="handleDeleteCategory"
          @save-category="handleSaveCategory"
        />
      </div>
    </template>
    <router-view />
  </form>
</template>

<script>
import {
  ChecHeader,
  ChecLoading,
} from '@chec/ui-library';
import { mutations } from '@/lib/pagination';
import addNotification from '@/mixins/addNotification';
import validateSchemaRequest from '@/lib/helpers/validateSchemaRequestHelper';
import crud from '@/mixins/crud';
import confirm from '@/mixins/confirm';
import categorySchema from '../schemas/category';
import Parents from '../components/edit/Parents.vue';
import DetailsCard from '../components/edit/Details.vue';
import Assets from '../components/edit/Assets.vue';
import ActionsCard from '../components/edit/ActionsCard.vue';
import SubCategoriesCard from '../components/edit/SubCategoriesCard.vue';
import Breadcrumbs from '../components/Breadcrumbs.vue';

export default {
  name: 'AddEdit',
  components: {
    ActionsCard,
    Assets,
    Breadcrumbs,
    ChecHeader,
    ChecLoading,
    DetailsCard,
    Parents,
    SubCategoriesCard,
  },
  mixins: [
    addNotification,
    crud('categories'),
    confirm,
  ],
  data() {
    return {
      category: {
        parent_id: '',
        name: '',
        permalink: '',
        slug: '',
        description: '',
        assets: [],
        breadcrumbs: [],
      },
      validationErrors: {},
      isSaving: false,
    };
  },
  computed: {
    categoryId() {
      return this.$route.params.id;
    },
    /**
     * Determines wether we are on the add/edit categories route.
     *
     * @returns {boolean}
     */
    isAdding() {
      return this.$route.name === 'categories.add';
    },
    /**
     * @returns {string}
     */
    pageTitle() {
      return this.isAdding
        ? this.$t('category.add')
        : this.$t('category.edit');
    },
  },
  watch: {
    $route(to) {
      this.refreshCategory(this.get(to.params.id));
    },
  },
  mounted() {
    // Monitor changes to this category that might happen elsewhere, like adding child categories
    this.$store.subscribe((mutation) => {
      if (mutation.type !== `categories/${mutations.REPLACE_ITEM}`) {
        return;
      }

      if (mutation.payload.item.id !== this.categoryId) {
        return;
      }

      this.refreshCategory(mutation.payload.item);
    });

    if (!this.categoryId) {
      return;
    }

    this.load(this.categoryId)
      .then(() => {
        this.refreshCategory(this.get(this.$route.params.id));
      })
      .catch(() => {
        this.$router.push({ name: 'notFound' });
      });
  },
  methods: {
    refreshCategory(category) {
      const categoryData = { ...category };
      categoryData.description = categoryData.description || '';
      categoryData.permalink = categoryData.permalink || categoryData.slug;
      this.category = categoryData;
    },
    /**
     * Handle saving the category, then close the modal
     */
    handleSaveCategory() {
      this.validationErrors = {};
      this.isSaving = true;
      validateSchemaRequest(categorySchema, this.category, {
        abortEarly: false,
        context: { edit: true },
      })
        .then(
          (validatedData) => (this.isAdding
            ? this.create(validatedData, true)
            : this.update(this.categoryId, validatedData)),
        )
        .then(({ id }) => {
          this.addNotification(
            this.isAdding ? this.$t('category.added') : this.$t('category.updated'),
          );
          if (this.isAdding) {
            this.$router.push({ name: 'categories.edit', params: { id } });
          }
        })
        .catch((error) => {
          if (error.name === 'ValidationError') { // yup schema validation error
            this.validationErrors = error.errors;
            return;
          }

          if (error.response?.status === 422) {
            // Flatten all the errors into a one dimensional list
            const errors = Object.entries(error.response.data.error?.errors || {})
              .reduce((acc, [field, messages]) => ([
                ...acc,
                ...messages.map((message) => ({ field, message })),
              ]), []);

            // Use errors that we expect, and turn them into something than can be used with the
            // validationErrors prop
            this.validationErrors = errors.reduce((acc, { field, message }) => {
              if (field === 'slug') {
                return {
                  ...acc,
                  permalink: message,
                };
              }
              return acc;
            }, {});
          }

          this.addNotification(this.$t('category.updateError'), 'error');
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    /**
     * Confirm and delete the category.
     */
    async handleDeleteCategory() {
      if (!await this.confirm(
        this.$t('category.confirmDeleteTitle'),
        this.$t('category.confirmDelete'),
      )) {
        return;
      }

      // Send user immediately back to categories list
      const originalRoute = this.$router.currentRoute;
      const originalCategoryId = this.categoryId;
      await this.$router.push({ name: 'categories.home' });

      if (originalCategoryId === undefined) {
        // Category wasn't saved yet, do nothing.
        return;
      }

      this.delete(originalCategoryId)
        .then(() => {
          this.addNotification(this.$t('category.deleted'));
        })
        .catch((error) => {
          this.addNotification(this.$t('category.deleteFailed'), 'error');
          this.$router.push(originalRoute); // send user back to category view
          throw error;
        });
    },
  },
};
</script>

<style lang="scss">
.categories-add-edit {
  @apply text-gray-500;

  &__side-bar {
    @screen lg {
      @apply ml-8 w-1/3 max-w-sm;
    }
  }

  @screen lg {
    &__content {
      @apply flex flex-row;
    }

    &__main {
      @apply flex-grow;
    }
  }

  &__breadcrumbs {
    @apply mt-1;
  }

  &__content {
    @apply mt-8;
  }

  &__actions-card {
    @apply hidden;

    @screen lg {
      @apply block;
    }

    &--mobile {
      @screen lg {
        @apply hidden;
      }
    }
  }

  .card {
    @apply mb-8;
  }

  .details-card {
    @apply p-4;

    .chec-header {
      @apply mb-4;
    }
  }

  .slideout-panel {
    // Hack to avoid overlapping loading components
    @apply z-50;
  }
}
</style>
