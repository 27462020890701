<template>
  <DashboardTable
    class="categories-table"
    :align-right-after="2"
    :columns="[
      $t('general.name'),
      $t('general.slug'),
      $t('general.options'),
    ]"
    show-search
    state-key="categories"
  >
    <CategoryTableRow
      v-for="({ id, name, slug, breadcrumbs }) in categories"
      :id="id"
      :key="id"
      :slug="slug"
      :name="name"
      :breadcrumbs="breadcrumbs"
      @edit-category="(idToEdit) => $emit('edit-category', idToEdit)"
      @delete-category="(idToDelete) => $emit('delete-category', idToDelete)"
    />
  </DashboardTable>
</template>

<script>
import CategoryTableRow from '@/modules/categories/components/list/CategoryTableRow.vue';
import DashboardTable from '@/components/DashboardTable.vue';

export default {
  name: 'CategoriesTable',
  components: {
    DashboardTable,
    CategoryTableRow,
  },
  props: {
    categories: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.categories-table {
  @apply text-sm text-gray-500;

  .chec-table__table {
    @apply w-full;
  }

  td:not(.categories-table__loading-row):last-child,
  th:last-child {
    @apply text-right w-32;
  }

  &__loading-row {
    @apply relative;

    &--loading {
      @apply py-16;
    }
  }

  &__empty-text {
    @apply text-gray-400;
  }
}
</style>
