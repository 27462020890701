<template>
  <ChecCard
    class="category-details__card"
    inner-class="details-card"
  >
    <ChecHeader
      variant="card"
      :title="$t('general.details')"
    />
    <div class="input-wrapper__row space-x-2">
      <div class="input-wrapper">
        <TextField
          v-model="name"
          name="name"
          :value="name"
          :label="$t('general.name')"
          :variant="errors && errors['name'] ? 'error' : ''"
        />
        <span
          v-if="errors && errors['name']"
          class="input-wrapper__error"
        >
          {{ errors['name'] }}
        </span>
      </div>
      <div class="input-wrapper">
        <TextField
          v-model="permalink"
          name="permalink"
          :value="permalink"
          :label="$t('category.permalink')"
          :variant="errors && errors['permalink'] ? 'error' : ''"
        />
        <span
          v-if="errors && errors['permalink']"
          class="input-wrapper__error"
        >
          {{ errors['permalink'] }}
        </span>
      </div>
    </div>
    <div class="input-wrapper">
      <TextField
        name="description"
        multiline
        :value="category.description"
        :label="$t('general.description')"
        @input="value => change('description', value)"
      />
    </div>
  </ChecCard>
</template>

<script>
import {
  ChecCard,
  ChecHeader,
  TextField,

} from '@chec/ui-library';
import formatSlug from '@/mixins/formatSlug';

export default {
  name: 'Detail',
  components: {
    ChecCard,
    ChecHeader,
    TextField,
  },
  mixins: [formatSlug],
  model: {
    prop: 'category',
    event: 'input',
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hasPermalinkDeviated: false,
    };
  },
  computed: {
    permalink: {
      get() {
        return this.category.permalink;
      },
      set(newValue) {
        this.hasPermalinkDeviated = true;
        this.change('permalink', this.formatSlug(newValue));
      },
    },
    name: {
      get() {
        return this.category.name;
      },
      set(newValue) {
        this.change('name', newValue);
      },
    },
  },
  watch: {
    name(val) {
      if (!this.hasPermalinkDeviated || this.permalink === '') {
        this.change('permalink', this.formatSlug(val));
        this.hasPermalinkDeviated = false;
      }
    },
  },
  methods: {
    change(prop, value) {
      this.$emit('input', {
        ...this.category,
        [prop]: value,
      });
    },
  },
};
</script>
