<template>
  <ChecCard
    class="sub-categories-card"
    tailwind="p-4"
  >
    <ChecHeader
      variant="card"
      :title="$t('category.subCategories')"
      class="sub-categories-card__header"
    >
      <ChecButton
        class="sub-categories-card__button"
        color="primary"
        variant="round"
        icon="plus"
        tag-type="route"
        :to="{ name: 'categories.edit.subCategory.add' }"
      >
        {{ $t('general.add') }}
      </ChecButton>
    </ChecHeader>
    <p v-if="categoryChildren && !categoryChildren.length">
      {{ $t('category.noSubCategories') }}
    </p>
    <ChecVerticalNavigation
      v-else
      inline
      class="sub-categories-card__list"
    >
      <ChecVerticalNavItem
        v-for="item in categoryChildren"
        :key="item.id"
        :to="{ name: 'categories.edit', params: { id: item.id } }"
      >
        {{ item.name }}
      </ChecVerticalNavItem>
    </ChecVerticalNavigation>
  </ChecCard>
</template>

<script>
import {
  ChecButton,
  ChecCard,
  ChecHeader,
  ChecVerticalNavigation,
  ChecVerticalNavItem,
} from '@chec/ui-library';

export default {
  name: 'SubCategoriesCard',
  components: {
    ChecButton,
    ChecCard,
    ChecHeader,
    ChecVerticalNavigation,
    ChecVerticalNavItem,
  },
  props: {
    categoryChildren: Array,
  },
};
</script>

<style lang="scss">
.sub-categories-card {
  &__header {
    @apply pb-4;
  }
}
</style>
