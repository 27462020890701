<template>
  <ChecCard
    class="category-parents__card"
    inner-class="details-card"
  >
    <ChecLoading v-if="isLoading" />
    <ChecHeader
      variant="card"
      :title="$t('general.parent')"
    />
    <ResourceDropdown
      v-if="availableItemCount > 0 && !isLoading"
      v-model="parentCategory"
      name="parent_id"
      :exclude="[categoryId]"
      state-key="categories"
      :placeholder="$t('category.chooseParentCategory')"
    />
    <template v-else>
      {{ messageCopy }}
    </template>
  </ChecCard>
</template>

<script>
import {
  ChecHeader,
  ChecCard,
  ChecLoading,
} from '@chec/ui-library';
import { mapActions, mapState } from 'vuex';
import actions from '@/lib/pagination/actions';
import ResourceDropdown from '@/components/ResourceDropdown.vue';

export default {
  name: 'Parents',
  components: {
    ChecHeader,
    ChecLoading,
    ChecCard,
    ResourceDropdown,
  },
  model: {
    prop: 'parentId',
    event: 'change',
  },
  props: {
    categoryId: String,
    parentId: String,
  },
  data() {
    return {
      // Triggered when the component first mounts, and used to decide whether
      // to render the ResourceDropdown
      isLoading: true,
      // We track the total number that's un-searched, as total count provided by the pagination
      // library shows the total number matching the current search
      unsearchedTotal: null,
    };
  },
  computed: {
    ...mapState('categories', ['totalCount']),
    messageCopy() {
      return this.isLoading
        ? this.$t('general.loading')
        : this.$t('category.noAvailableCategories');
    },
    parentCategory: {
      get() {
        return this.parentId || '';
      },
      set(value) {
        this.$emit('change', value);
      },
    },
    availableItemCount() {
      if (this.categoryId !== '') {
        // Exclude the current product as an option (when the current product actually exists)
        return this.unsearchedTotal - 1;
      }
      return this.unsearchedTotal;
    },
  },
  async mounted() {
    if (this.totalCount === null) {
      await this.loadCategories();
    }
    this.unsearchedTotal = this.totalCount;
    this.isLoading = false;
  },
  methods: {
    ...mapActions('categories', { loadCategories: actions.LOAD_REMAINING_ITEMS }),
  },
};
</script>

<style lang="scss">
.category-parents {
  p {
    @apply font-bold;
  }
}
</style>
