import {
  string, object,
} from 'yup';
import i18n from '@/lang';

export default object().shape({
  name: string().required(i18n.t('category.pleaseEnterName')),
  permalink: string().required(i18n.t('category.pleaseEnterPermalink')),
  description: string().when('$edit', {
    is: true, // an update category request
    then: string().transform((cv) => ((!cv || !cv.trim()) ? null : cv)).nullable(),
    otherwise: string(),
  }),
});
