export default {
  methods: {
    formatSlug(slug) {
      return slug
        // Ony allow letters, numbers, and dashes
        .replace(/[^A-Z0-9]/ig, '-')
        // Replace multiple concurrent dashes with a single one
        .replace(/-+/g, '-')
        // Trim dashes from start and end
        .replace(/^-+|-+$/g, '')
        .toLowerCase();
    },
  },
};
