<template>
  <component
    :is="separatedLinks ? 'span' : 'router-link'"
    v-if="breadcrumbs"
    v-bind="rootBindings"
    class="categories-breadcrumbs"
  >
    <template v-for="crumb in truncatedBreadcrumbs">
      <ChecIcon
        v-if="crumb.id === 'tmp'"
        :key="`name-${crumb.id}`"
        v-tooltip="tooltipBreadcrumbs"
        icon="more"
        size="sm"
        class="categories-breadcrumbs__more"
      />
      <component
        :is="separatedLinks ? 'router-link' : 'span'"
        v-else
        :key="`name-${crumb.id}`"
        v-bind="breadcrumbBindings(crumb)"
      >
        {{ crumb.name }}
      </component>
      <ChecIcon
        :key="`separator-${crumb.id}`"
        icon="right-arrow"
        class="categories-breadcrumbs__separator"
      />
    </template>
    {{ activeBreadcrumb && activeBreadcrumb.name }}
  </component>
</template>

<script>
import { ChecIcon } from '@chec/ui-library';

export default {
  name: 'Breadcrumbs',
  components: {
    ChecIcon,
  },
  props: {
    activeBreadcrumb: Object,
    breadcrumbs: {
      type: Array,
      default: () => ([]),
    },
    separatedLinks: Boolean,
    collapseLimit: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    tooltipBreadcrumbs() {
      return this.breadcrumbs
        .map((crumb) => crumb.name)
        .slice(1)
        .join(' → ');
    },
    rootBindings() {
      if (this.separatedLinks || !this.activeBreadcrumb) {
        return {};
      }

      const { id } = this.activeBreadcrumb;
      return { to: { name: 'categories.edit', params: { id } } };
    },
    truncatedBreadcrumbs() {
      if (this.collapseLimit && this.breadcrumbs.length < this.collapseLimit) {
        return this.breadcrumbs;
      }
      // Return the first and last, then ellipsis the middle
      return [
        this.breadcrumbs[0],
        { id: 'tmp', name: '...' },
      ];
    },
  },
  methods: {
    breadcrumbBindings({ id }) {
      if (!id) {
        return {};
      }
      return { to: { name: 'categories.edit', params: { id } } };
    },
  },
};
</script>

<style lang="scss">
.categories-breadcrumbs {
  @apply flex items-center;

  &__separator {
    @apply h-4 w-4 mx-1 inline;
  }

  &__more {
    @apply inline mt-px;
  }

  @screen md {
    &__separator {
      @apply h-5 w-5 mx-2;
    }
  }
}
</style>
